/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@1,700&display=swap');

@media (max-width: 820px) {
  .desktop {
    display: none;
  }
}

@media (min-width: 820px) {
  .mobile {
    display: none;
  }
}
ion-content,
ion-header,
ion-footer {
  --background: var(--ion-background-color, #ffffff);
}
#container-block {
  background: none repeat scroll 0 0;
  border-color: var(--ion-background-border-color, #ffffff);
  border-style: solid;
  border-width: 0 10px 0 10px;
}
#container-block-2 {
  background: none repeat scroll 0 0;
  border-color: var(--ion-background-border-color, #ffffff);
  border-style: solid;
  border-width: 0 10px 0 10px;
  background: var(--ion-background-secondary-color, #ffffff);
}
#container-block-2 ion-item {
  --background: var(--ion-background-secondary-color, #ffffff);
}
ion-footer {
  background-color: var(--ion-background-color, #ffffff);
}
ion-text{
  font-family:  'Exo 2', sans-serif;
}
h1{
  font-size: 3vw;
}
.et_pb_with_border {
  position: relative;
  border: 0 solid #333;
}